body{
  background-color: #f3f3f3;
  font-family: Helvetica, Arial, sans-serif;
}

.dm-pageHeader{
  color:#4a4a4a;
  margin: 28px 0 10px 0;
  padding:0;
  border-bottom: none;
}

.dm-font-header-bold {
  font-size: 32px;
  font-weight: 700;
}

.busstop_route_div_button_disabled {
  border-width: 1px;
  border-style: solid;
  border-color: gray;
  color: gray;
  background-color: #f0f0f0;
  display: block;
  min-height: 50px;
  padding:10px;
  position: relative;
  text-decoration: none;
  margin: 3px 0;
  border-radius: 0.25rem;
}

.busstop_route_div_button {
  border-width: 1px;
  border-style: solid;
  border-color: #0f61a9;
  background-color: white;
  display: block;
  min-height: 50px;
  padding:10px;
  position: relative;
  text-decoration: none;
  margin: 3px 0;
  border-radius: 0.25rem;
}

div.busstop_route_div_button:hover {
  background-color: #0F61A9;
  color:white;
}